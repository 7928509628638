import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";
@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  setUser(user: Sentry.User | null) {
    Sentry.setUser(user);
  }

  captureMessage(message: string) {
    Sentry.captureMessage(message, {level: 'debug'});
  }

  captureException(exception: any) {
    Sentry.captureException(exception);
  }
}
