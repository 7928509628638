import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceManagementComponent} from './device-management.component';
import {TranslateModule} from "@ngx-translate/core";
import {NgxTranslateCutModule} from "ngx-translate-cut";


@NgModule({
  declarations: [DeviceManagementComponent],
  exports: [
    DeviceManagementComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxTranslateCutModule
  ]
})
export class DeviceManagementModule {
}
