import {Injectable} from '@angular/core';
import {
  IFacebookLoginInfoResponse,
  IFacebookLoginResponse,
  IGoogleLoginResponse,
  INewLoginResponse
} from "@interfaces/user/user-common.interface";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {AddEmailDialogComponent} from "@components/add-email-dialog/add-email-dialog.component";
import {DeviceManagementDialogComponent} from "@components/device-management-dialog/device-management-dialog.component";
import {IDevice} from "@interfaces/common/device.interface";
import {
  VerificationInputDialogComponent
} from "@components/verification-input-dialog/verification-input-dialog.component";
import {SnackBarControlService} from "@services/snack-bar/snack-bar-control.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {environment} from "@environments/environment";
import {IEmailSignup} from "@interfaces/common/auth.interface";
import {BrazeUserEventsService} from "@services/marketing/braze/events/braze-user-events.service";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {TrackingService} from "@services/tracking/tracking.service";
import {EUserType} from "@interfaces/common/connector.interface";

interface IUserCredentials {
  email: string,
  password: string,
  remember: boolean
}

enum ELoginType {
  EMAIL = 1,
  FACEBOOK,
  GOOGLE,
}

interface ILoginInfo {
  type: ELoginType,
  platform: string | null,
  subDomain: string | null,
  userCredentials?: IUserCredentials,
  accessToken?: string,
  code?: string,
  redirectUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthControlV2Service {

  platformCode: string | null = null;
  subDomain: string | null = null;

  constructor(private authService: AuthV2Service, private dialogService: DialogService,
              private snackbarControl: SnackBarControlService, private uiLoader: UiLoaderService,
              private connectorV2Service: ConnectorV2Service,
              private brazeUserEventsService: BrazeUserEventsService,
              private gtmService: GoogleTagManagerService,
              private trackingService: TrackingService) {
  }

  facebookLogin(accessToken: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.FACEBOOK,
        platform: this.platformCode,
        subDomain: this.subDomain,
        accessToken: accessToken
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  googleLogin(accessToken: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.GOOGLE,
        platform: this.platformCode,
        subDomain: this.subDomain,
        accessToken: accessToken
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  googleLoginWithCode(code: string, redirectUrl: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.GOOGLE,
        platform: this.platformCode,
        subDomain: this.subDomain,
        code: code,
        redirectUrl: redirectUrl
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  emailLogin(userCredentials: IUserCredentials): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.EMAIL,
        platform: this.platformCode,
        subDomain: this.subDomain,
        userCredentials: userCredentials
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  emailSignup(signupForm: IEmailSignup): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let signupInfo: IEmailSignup = {
        ...signupForm,
        platform: this.platformCode ?? undefined
      };
      this.authService.emailSingUp('sign-up', signupInfo).then(resolve).catch(reject);
    });
  }

  private getFacebookInfo(accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return new Promise<IFacebookLoginInfoResponse>((resolve, reject) => {
      this.authService.getFacebookInfo('account', accessToken)
        .then((facebookInfo) => {
          if (facebookInfo.email) {
            resolve(facebookInfo);
          } else {
            this.uiLoader.stopUiLoader('login-process');
            this.addEmailDialog().then((email) => {
              resolve({
                email: email,
                ...facebookInfo
              });
            }).catch(reject);
          }
        })
        .catch(reject)
    });
  }

  private verifyEmailLogin(userCredentials: IUserCredentials, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.authService.emailLogin('account', userCredentials.email, userCredentials.password, platform, subDomain)
        .then((loginRes) => {
          resolve(loginRes);
        }).catch(reject);
    });
  }

  private verifyFacebookLogin(accessToken: string, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    return new Promise<IFacebookLoginResponse>((resolve, reject) => {
      this.getFacebookInfo(accessToken).then((facebookInfo) => {
        this.uiLoader.startUiLoader('login-process');
        this.authService.facebookLogin('account', facebookInfo, platform, subDomain)
          .then((facebookLoginResponse: IFacebookLoginResponse) => {
            if (facebookLoginResponse.isLoginFirstTime) {
              this.brazeUserEventsService.sendBrazeSignUpEvent(facebookLoginResponse, ELoginTypes.FACEBOOK);
              this.trackingService.signupActivationEvent({
                authentication_type: ELoginTypes.FACEBOOK,
                status: true
              });
            } else {
              this.trackingService.loginSuccessfullyEvent({
                authentication_type: ELoginTypes.FACEBOOK
              });
            }
            this.gtmService.sendSocialLoginEvent(facebookLoginResponse);
            setTimeout(() => {
              resolve(facebookLoginResponse);
            });
          }).catch(reject);
      }).catch(reject);
    });
  }

  private loginProcess(loginInfo: ILoginInfo, count: number = 1): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.uiLoader.startUiLoader('login-process');
      this.verifyLoginInfo(loginInfo).then((verifyResponse) => {
        if (verifyResponse) {
          this.connectorV2Service.isCheckingAuthorization = true;
          this.logUserIn(verifyResponse).then(resolve).catch(reject);
        } else {
          reject();
        }
      }).catch((e) => {
        this.uiLoader.stopUiLoader('login-process');
        reject(e);
      });
    });
  }

  private verifyGoogleLogin(accessToken: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.authService.googleLogin('account', accessToken, platform, subDomain)
        .then((loginRes) => {
          resolve(loginRes);
        }).catch(reject);
    });
  }

  private verifyGoogleLoginWithCode(code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return new Promise<IGoogleLoginResponse>((resolve, reject) => {
      this.authService.googleLoginWithCode('account', code, redirectUrl, platform, subDomain)
        .then((googleLoginResponse: IGoogleLoginResponse) => {
          if (googleLoginResponse.isLoginFirstTime) {
            this.brazeUserEventsService.sendBrazeSignUpEvent(googleLoginResponse, ELoginTypes.GOOGLE);
            this.trackingService.signupActivationEvent({
              authentication_type: ELoginTypes.GOOGLE,
              status: true
            });
          } else {
            this.trackingService.loginSuccessfullyEvent({
              authentication_type: ELoginTypes.GOOGLE
            });
          }
          this.gtmService.sendSocialLoginEvent(googleLoginResponse);
          setTimeout(() => {
            resolve(googleLoginResponse);
          });
        }).catch(reject);
    });
  }


  private verifyLoginInfo(loginInfo: ILoginInfo): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (loginInfo.type === ELoginType.EMAIL && loginInfo.userCredentials) {
        this.verifyEmailLogin(loginInfo.userCredentials, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.FACEBOOK && loginInfo.accessToken) {
        this.verifyFacebookLogin(loginInfo.accessToken, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.GOOGLE && loginInfo.accessToken) {
        this.verifyGoogleLogin(loginInfo.accessToken, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.GOOGLE && loginInfo.code && loginInfo.redirectUrl) {
        this.verifyGoogleLoginWithCode(loginInfo.code, loginInfo.redirectUrl, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else {
        reject();
      }
    });
  }

  private removeDevice(loginInfo: ILoginInfo): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.removeDevicePopup(loginInfo).then((device) => {
        this.verifyEmailPopup(loginInfo, device).then((isRemoved) => {
          if (isRemoved) {
            resolve(isRemoved);
          } else {
            reject();
          }
        }).catch(reject);
      }).catch(reject);
    });
  }

  private removeDevicePopup(loginInfo: ILoginInfo): Promise<IDevice> {
    return new Promise<IDevice>((resolve, reject) => {
      this.dialogService.openDialog(DeviceManagementDialogComponent, 'device-manage',
        loginInfo).afterClosed().toPromise().then(device => {
        if (device) {
          resolve(device);
        } else {
          reject();
        }
      });
    });
  }

  private verifyEmailPopup(loginInfo: ILoginInfo, device: IDevice): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dialogService.openDialog(VerificationInputDialogComponent, 'verification-input',
        {
          removeDevice: true,
          device: device,
          ...loginInfo
        }, {disableClose: true}).afterClosed().toPromise().then(result => {
        if (result?.deviceRemoved) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  private logUserIn(loginResponse: INewLoginResponse): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.snackbarControl.closeAllSnackBars();
      this.connectorV2Service.addUser({
        uuid: loginResponse.uuid,
        type: loginResponse.platformName ? EUserType.B2B : EUserType.B2C,
        link: loginResponse.platformName ? `https://${loginResponse.platformName.toLowerCase()}.${environment.platforms.base.link}` : null
      }).finally(() => {
        this.uiLoader.stopUiLoader('login-process');
        resolve(loginResponse);
      });
    });
  }

  private addEmailDialog(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.dialogService.openDialog(AddEmailDialogComponent, 'add-email', {}, {
        disableClose: true,
        closeOnNavigation: false
      }).afterClosed().toPromise().then((emailData: { email: string }) => {
        if (emailData?.email) {
          resolve(emailData.email);
        } else {
          reject();
        }
      });
    });
  }

}
