import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IDemographics, IInterest} from '@interfaces/common/auth.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {
  IAccountInfo,
  IB2bBannerInfo,
  ILoyaltyInfo,
  IPersonalInfo,
  IPurchaseLog,
  ISocialMediaProfiles,
  IUserPlatform
} from '@interfaces/user/user-common.interface';
import {ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {IDevice} from '@interfaces/common/device.interface';
import {HTTPApiURLs} from '@interfaces/common/http.interface';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  private services = {
    getUser: 'getUser/api/users',
    getUserTransaction: 'getUser/api/transaction',
    addEditUser: 'addEditUser/api/users',
    sso: 'SSO/api/sso'
  };

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllInterestsByLanguageId(sender: string, languageId: number): Promise<IInterest[]> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.getUser}/GetAllInterestsByLanguageId`,
      sender: sender,
      receiver: 'GetAllInterestsByLanguageId',
      body: {languageId: languageId},
    });
  }

  saveUserInterests(sender: string, interests: number[]): Promise<any> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.addEditUser}/SaveUserInterest`,
      sender: sender,
      receiver: 'SaveUserInterest',
      body: {rankedTagIds: interests}
    });
  }

  saveUserDemographics(sender: string, demographics: IDemographics): Promise<any> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.addEditUser}/UpdateUserData`,
      sender: sender,
      receiver: 'UpdateUserData',
      body: demographics
    });
  }

  authorizeUser(sender: string): Promise<any> {
    return this.customHttpService.sendRequest({
      endpoint: 'auth/authorize',
      sender: sender,
      receiver: 'authorize',
      body: {}
    }, true);
  }

  getUserInfo(): Promise<IUserInfo> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.getUser}/mini/info`,
      sender: 'app',
      receiver: 'info',
      body: {}
    });
  }

  getPersonalInfo(sender: string): Promise<IPersonalInfo> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.getUser}/userInfo`,
      sender: sender,
      receiver: 'getUserInfo',
      body: {},
    });
  }

  getSocialMediaProfiles(sender: string): Promise<ISocialMediaProfiles[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'users/socialMedia',
      sender: sender,
      receiver: 'getSocialMediaProfiles',
      body: {}
    });
  }

  getAccountInfo(sender: string): Promise<IAccountInfo> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.getUser}/accountInfo`,
      sender: sender,
      receiver: 'getAccountInfo',
      body: {},
    });
  }

  updatePersonalInfo(sender: string, payload: IPersonalInfo): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.addEditUser}/update/personalInfo`,
      sender: sender,
      receiver: 'updatePersonalInfo',
      body: payload
    });
  }

  updateSocialMediaProfiles(sender: string, socialProfiles: { channelUrl: string }[]) {
    return this.customHttpService.sendRequest({
      endpoint: 'users/save/socialMediaProfiles',
      sender: sender,
      receiver: 'updateSocialMediaProfiles',
      body: {
        socialProfiles
      }
    });
  }

  getUserSubscriptionPlan(sender: string, languageId: number): Promise<ISubscriptionPlan> {
    return this.customHttpService.sendRequest({
      endpoint: 'subscriptions/user/plan',
      sender: sender,
      receiver: 'plan',
      body: {
        languageId: languageId
      }
    });
  }

  getUserPurchaseLogOrders(sender: string, languageId: number): Promise<IPurchaseLog[]> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.getUserTransaction}/user/orders`,
      sender: sender,
      receiver: 'orders',
      body: {
        languageId: languageId
      },
    });
  }

  changeMobileNumber(sender: string, changeMobileNumberBody: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.addEditUser}/update/mobileNumber`,
      sender: sender,
      receiver: 'mobileNumber',
      body: changeMobileNumberBody
    });
  }

  sendPinCode(sender: string, sendPinCodeObject: object): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'emails/sendPinCode',
      sender: sender,
      receiver: 'sendPinCode',
      body: sendPinCodeObject
    })
  }

  verifyEmailPinCode(sender: string, activationObject: {pinCode: number,
    userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}}): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'emails/verifyPinCode',
      sender: sender,
      receiver: 'verifyPinCode',
      body: activationObject
    });
  }

  activateMobileNumber(sender: string, activationCode: number): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'sms/verifyPinCode',
      sender: sender,
      receiver: 'verifyPinCode',
      body: {
        pinCode: activationCode
      }
    });
  }

  getUserDevices(userInfo: {email: string, password: string, userType: number} | {}): Promise<IDevice[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'auth/device',
      sender: 'devices',
      receiver: 'device',
      body: userInfo
    });
  }

  removeUserDevice(removeDevice: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'auth/device/logout',
      sender: 'devices',
      receiver: 'logout',
      body: removeDevice
    });
  }

  getLoyaltyInfo(): Promise<ILoyaltyInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'campaign/checkLoyalUser',
      sender: 'app',
      receiver: 'checkLoyalUser',
      body: {}
    });
  }

  getB2bBannerInfo(languageId?: number): Promise<IB2bBannerInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'organization/bannerData',
      sender: 'homePage',
      receiver: 'b2bBannerInfo',
      body: {
        languageId: languageId
      }
    })
  }

  getUserPlatforms(): Promise<IUserPlatform[]>{
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.sso}/getUserPlatforms`,
      sender: 'redirect-center',
      receiver: '',
      body: {}
    });
  }
}
