import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginAuthGuard} from "@guards/login-auth.guard";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/login'},
  {
    path: 'login',
    loadChildren: () => import('@login-modules/login/login.module')
      .then(mod => mod.LoginModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('@login-modules/signup/signup.module')
      .then(mod => mod.SignupModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('@login-modules/forget-password/forget-password.module')
      .then(mod => mod.ForgetPasswordModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('@login-modules/reset-password/reset-password.module')
      .then(mod => mod.ResetPasswordModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'redirect-center',
    loadChildren: () => import('@login-modules/redirect-center/redirect-center.module')
      .then(mod => mod.RedirectCenterModule)
  },
  {
    path: '404',
    loadChildren: () => import('@login-modules/not-found/not-found.module')
      .then(mod => mod.NotFoundModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: '**', redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'top',
    initialNavigation: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
