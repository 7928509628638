import {Injectable} from '@angular/core';
import {ConversionStrings, IAdSourceContents, IConversionSource} from '@interfaces/marketing/marketing.interface';
import {MarketingHttpService} from '@services/integrations/marketing/marketing-http.service';
import {IAdOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {SubscriptionMarketingService} from '@services/marketing/subscription-marketing.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {environment} from '@environments/environment';
import {Params} from '@angular/router';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {BrazeSubscriptionEventsService} from '@services/marketing/braze/events/braze-subscription-events.service';
import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';
import {EncryptionService} from '@services/request-token/encryption.service';
import {CookieService} from "ngx-cookie";
import {SentryService} from "@services/sentry/sentry.service";
import {EPaymentStatusId, IPaymentStatus} from "@interfaces/payment-website/payment-status.interface";
import {TrackingEventName} from "@services/tracking/tracking.interface";
import {TrackingService} from "@services/tracking/tracking.service";

const CONVERSION_MAX_DAYS = 11;

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  conversionDomain = environment.platforms.base.link;

  constructor(private marketingHttpService: MarketingHttpService, private brazeService: BrazeService,
              private brazeSubscriptionEventsService: BrazeSubscriptionEventsService, private gtmService: GoogleTagManagerService,
              private subscriptionMarketingService: SubscriptionMarketingService,
              private encryptionService: EncryptionService,
              private cookieService: CookieService,
              private sentryService: SentryService,
              private trackingService: TrackingService,
              ) {
  }

  private setAdOrderDetailsForGTM(order: IAdOrderDetails) {
    let coupon = undefined;
    let item = undefined;
    let paymentMethod: IPaymentMethod = PaymentMethods[order.paymentMethod] as IPaymentMethod;
    this.sentryService.captureMessage(`${paymentMethod}: Payment status ${EPaymentStatusId[order?.status]}`);
    if (order.items[0]) {
      if (order.items[0]?.discountItems) {
        coupon = order.items[0].discountItems[0]?.discountIdentifier
      }
      item = {
        id: order.items[0].item.id,
        name: this.subscriptionMarketingService.getSubscriptionPlanName(order.items[0].item.id),
        coupon: coupon
      }
    }
    if (item) {
      if (order.status === 3) {
        this.brazeSubscriptionEventsService.successPayment(order);
        this.gtmService.successfulPaymentStatus({
          trn: order.orderId,
          price: order.finalPrice,
          currency: order.currency,
          discount: order.originalPrice - order.finalPrice,
          item: item,
          method: paymentMethod
        });
      } else if (order.status === 2) {
        this.brazeService.logEvent('payment_pending');
        this.gtmService.pendingPaymentStatus({
          method: paymentMethod,
          trn: order.orderId,
          item: item
        })
      } else {
        this.brazeService.logEvent('payment_failed');
        this.gtmService.failedPaymentStatus({
          method: paymentMethod,
          trn: order.orderId,
          item: item,
        });
      }
    }
    this.trackingService.sendEvent({
      name: TrackingEventName.SUBSCRIPTION_PAYMENT_STATUS,
      properties: {
        payment_status: EPaymentStatusId[order?.status] as IPaymentStatus,
        amount: order.finalPrice,
        transaction_id: order.orderId,
        payment_method: PaymentMethods[order.paymentMethod] as IPaymentMethod,
        discount_coupon: coupon,
        discount_percentage: order.items[0]?.discountItems
          ? (order.originalPrice - order.finalPrice) * 100 / order.finalPrice
          : undefined,
      }
    })
  }

  getAdUserOrderDetails(sender: string): void {
    this.marketingHttpService.getAdUSerOrder(sender).then((order) => {
      this.createPlatformConversionCookie({
        adSourceType: order.adSourceType,
        adSourceId: order.adSourceId,
        createdAt: order.adCreatedAt,
      });
      this.setAdOrderDetailsForGTM(order);
    }).catch((e) => {
    })
  }

  getUserAd(sender: string): void {
    this.marketingHttpService.getUserAd(sender).then((userAd) => {
      const  processAdSourceContent = (adSourceId: string) => {
        if (this.encryptionService.isValidBase64(userAd.adSourceId)) {
          return  JSON.parse(this.encryptionService.decodeBase64ToString(userAd.adSourceId));
        } else {
          return adSourceId;
        }
      }
      if (userAd.adSourceId) {
        let processedAd: IConversionSource = {
          ...userAd,
          adSourceId: processAdSourceContent(userAd.adSourceId)
        };
        this.createPlatformConversionCookie(processedAd);
      }
    }).catch((e) => {
    })
  }

  checkForAdConversionCookie(): string | null {
    const conversionCookie = this.cookieService.get('conversion');
    if (conversionCookie) {
      return conversionCookie;
    } else {
      return null;
    }
  }

  deleteAdConversionCookie(): void {
    this.cookieService.remove('conversion', {
      domain: environment.platforms.base.link,
      path: '/',
      sameSite: "none",
      secure: true,
    });
  }

  createAdConversionCookie(routeParams: Params): void {
    let conversion: IConversionSource | undefined = this.getConversionFromURLQueryParams(routeParams);
    if (conversion) {
      let cookieDate = new Date();
      cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
      this.addCookie({
        name: 'conversion',
        value: JSON.stringify(conversion),
        domain: this.conversionDomain,
        expires: cookieDate,
      });
      this.createPlatformConversionCookie(conversion);
    }
  }

  createUTMCookie(utmQueryParams: string) {
    if (!utmQueryParams) return;
    let cookieDate = new Date();
    cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
    this.addCookie({
      name: 'alm-utm',
      value: `?${utmQueryParams}`,
      domain: this.conversionDomain,
      expires: cookieDate,
    });
  }

  createPlatformConversionCookie(conversionSource: IConversionSource) {
    let cookieDate = conversionSource.createdAt ? new Date(conversionSource.createdAt) : new Date();
    cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
    let clickId = '';
    let utmQueryParams = '';
    // TODO we will use string to support legacy adSourceId string after one month of deployment we can remove string checks
    if ( typeof conversionSource.adSourceId === 'string') {
      clickId = conversionSource.adSourceId
    } else {
      clickId = conversionSource.adSourceId?.clickId || '';
      utmQueryParams = conversionSource.adSourceId?.utmParams
    }
    switch (conversionSource.adSourceType) {
      case 'facebook':
        this.addCookie({
          name: '_fbc',
          value: `fb.1.${new Date().getTime()}.${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
      return;
      case 'google':
        this.addCookie({
          name: '_gcl_aw',
          value: `GCL.${Math.floor(new Date().getTime() / 1000)}.${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
        return;
      case 'tiktok':
        this.addCookie({
          name: 'ttclid',
          value: `${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
        return;
      case 'utmOnlyCampaign':
        this.createUTMCookie(utmQueryParams);
        return;
    }
  }

  addCookie({name, value, domain, expires}: { name: string, value: string, domain: string, expires: Date}) {
    this.cookieService.put(name, value, {
      domain,
      expires,
      path: '/',
      secure: true,
      sameSite: false
    });
  }

  addUserFromAd(sender: string, conversionSource: IConversionSource): Promise<{ result: boolean }> {
    const adSourceContent = this.encryptionService.encodeStringToBase64(JSON.stringify(conversionSource.adSourceId))
    return this.marketingHttpService.addUserFromAd(sender,conversionSource.adSourceType, adSourceContent);
  }

  private getConversionFromURLQueryParams(params: Params): IConversionSource | undefined {
    if (params[ConversionStrings.google]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.google],
          utmParams: new URLSearchParams(params).toString(),
        },
        adSourceType: 'google',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.facebook]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.facebook],
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'facebook',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.tiktok]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.tiktok],
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'tiktok',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.utmOnlyCampaign]) {
      return {
        adSourceId: {
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'utmOnlyCampaign',
        createdAt: new Date().toISOString(),
      }
    } else {
      return undefined
    }
  }
}
