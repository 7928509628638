import {IEnvironmentNames} from '@interfaces/environment/environment.interface';

export const environment = {
  production: true,
  environmentName: IEnvironmentNames.development,
  infrastructure: 'Staging',
  serviceUrls: {
    'javaOrch': 'https://revamp-dev-client-orchestrator.almentor.me/api',
    'ssoOrch': 'https://revamp-dev-api.almentor.me/api/sso',
    'newOrch': 'https://revamp-dev-api.almentor.me',
    'b2bOrch': 'https://revamp-dev-b2b.almentor.me',
    'adminOrch': 'https://revamp-dev-admin-orchestrator.almentor.me/api',
    'selfEnrollOrch': 'https://revamp-dev-b2b.almentor.me/selfEnroll/api'
  },
  platforms: {
    base: {link: 'almentor.dev'},
    anonymous: {link: 'https://www.almentor.dev'},
    b2c: {link: 'https://app.almentor.dev', code: '00'},
    admin: {link: 'https://admin.almentor.dev', code: '02'},
    dashboard: {link: 'https://mentor-dashboard.almentor.dev', code: '03'},
    ld: {link: 'https://orgadmin.almentor.dev', code: '05'},
    preview: {link: 'https://preview.almentor.dev', code: '02'},
    payment: {link: 'https://payment.almentor.dev', code: '2D'},
    marketing: {link: 'https://beta-business.almentor-mail.com'},
    sso: {link: 'https://account.almentor.dev'},
    futureX: {link: 'https://futurex.almentor.dev', code: '26'}
  },
  certificateValidator: 'https://dev-validate-certificate.almentor.me',
  cdnUrl: 'https://cdn-dev-revamp.almentor.me',
  connectorUrl: 'https://beta-auth.almentor.me',
  facebookId: '510357686322993',
  googleId: '987757772688-f5k9ug05jtlcjnal51r382gmum7s1gie.apps.googleusercontent.com',
  paypalAppId: "ARxNfx97XybcxfPA8utn8IkY8sJGtgfsofnseKkDHFB6QQQrrHsbg07m-5LP_AZTUWRrsfRGDOoevSyE",
  bcPlayerConfig: {
    accountId: '5034103592001',
    playerId: '2U6G2rQil'
  },
  firebase: {
    apiKey: "AIzaSyDEnnIJwM5-dudevBezKzbE87JGKNWsbEHM",
    authDomain: "almentor-revamp.firebaseapp.com",
    projectId: "almentor-revamp",
    storageBucket: "almentor-revamp.appspot.com",
    messagingSenderId: "539340182137",
    appId: "1:539340182137:web:bd1677fdc02977ca083aa8"
  },
  algolia: {
    apiKey: "da88ed68c2dcd599792d83b0f2c1505e",
    appId: "FRYEJSU9VH",
    indices: {
      keywords: "revamp_stg_KEYWORDs",
      courses: "revamp_stg_Course",
      bundles: "revamp_stg_Bundle",
      mentors: "revamp_stg_Mentor"
    }
  },
  gtmID: "GTM-K7TWMZF",
  amplitudeApiKey: "48dd76bdd0280ee485912234d433d374",
  braze: {
    apiKey: '84c4b8da-627b-4674-afc0-9e86a12265f0',
    baseUrl: 'sdk.fra-01.braze.eu'
  },
  isrServerConfig: {
    maxAge: 60, // seconds null | 0 | > 0
    staleDuration: 180, // seconds null | 0 | > 0,
    errorDuration: 0, // seconds null | 0 | > 0
  },
  mixpanel: {
    token: '5dd8964c087a8c75131d8f3fbe1541b9',
    debug: true
  }
};
