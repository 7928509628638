import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginContainerComponent } from './login-container.component';
import {SocialButtonsModule} from '@components/social-buttons/social-buttons.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from "@ngx-translate/core";
import {NgxTranslateCutModule} from "ngx-translate-cut";
import {TrackingModule} from "@services/tracking/tracking.module";

@NgModule({
  declarations: [LoginFormComponent, LoginContainerComponent],
  exports: [
    LoginFormComponent,
    LoginContainerComponent
  ],
    imports: [
        CommonModule,
        SocialButtonsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        NgxTranslateCutModule,
        TrackingModule
    ]
})
export class LoginContainerModule { }
