import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IDevice} from '@interfaces/common/device.interface';
import {LanguageControlService} from '@services/language/language-control.service';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from '@services/integrations/user/user.service';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {SnackBarControlService} from '@services/snack-bar/snack-bar-control.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {interval, Subscription, timer} from 'rxjs';
import {take} from 'rxjs/operators';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {AuthV2Service} from '@services/integrations/auth-v2/auth-v2.service';

@Component({
  selector: 'alm-root-verification-input-dialog',
  templateUrl: './verification-input-dialog.component.html',
  styleUrls: ['./verification-input-dialog.component.scss']
})
export class VerificationInputDialogComponent implements OnInit, OnDestroy {

  canSend: boolean = true;
  startTimerAt: number = 0;
  timer: string = '02:00';

  disableButton: boolean = true;
  private verificationCode: string = '';
  private device?: IDevice;
  private loginData?: { email: string, password: string, userType: number };
  private loginAccessToken?: { accessToken: string, userType: number };
  private isRemoveDevice: boolean = false;

  private subscriptions: Subscription[] = [];
  private readonly maxTime: number = 119;

  constructor(private languageControl: LanguageControlService, private dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) private removeDeviceDetails: {
                removeDevice: boolean,
                loginData: { email: string, password: string, userType: number },
                loginAccessToken: { accessToken: string, userType: number },
                device: IDevice
              }, private userService: UserService, private uiLoader: UiLoaderService,
              private snackbarControl: SnackBarControlService, private amplitudeService: AmplitudeService,
              private authV2Service: AuthV2Service) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.startTimerAt <= this.maxTime && this.startTimerAt > 0) {
      this.startTimer(this.startTimerAt);
    }
    if (this.removeDeviceDetails) {
      this.isRemoveDevice = this.removeDeviceDetails.removeDevice;
      this.loginData = this.removeDeviceDetails.loginData;
      this.loginAccessToken = this.removeDeviceDetails.loginAccessToken;
      this.device = this.removeDeviceDetails.device;
    }
  }

  private startTimer(startAt: number) {
    this.canSend = false;
    startAt = startAt > 0 && startAt <= this.maxTime ? startAt : this.maxTime;
    const timerInterval$ = interval(1000);
    let timerSub = timerInterval$.pipe(take(startAt)).subscribe((current) => {
      let countDownCurrent = startAt - current;
      let min = ('0' + parseInt(((countDownCurrent) / 60).toString(), 10)).slice(-2);
      let sec = ('0' + (countDownCurrent) % 60).slice(-2);
      this.timer = `${min}:${sec}`;
    });
    let timeUpSub = timer(120000).subscribe(() => {
      this.canSend = true;
      this.startTimerAt = this.maxTime;
      this.timer = '02:00';
    });
    this.subscriptions.push(timerSub);
    this.subscriptions.push(timeUpSub);
  }

  closeDialog() {
    this.dialogService.closeDialog('verification-input');
  }

  setDisableButtonState($event: boolean) {
    this.disableButton = $event;
  }

  setVerificationCode($event: string) {
    this.verificationCode = $event;
  }

  verifyCode() {
    let verificationCodeAsNumber = Number(this.verificationCode);
    if (verificationCodeAsNumber) {
      this.uiLoader.startUiLoader('verify-code');
      this.authV2Service.verifyEmailPinCode('verification-dialog', verificationCodeAsNumber,
        this.loginData ? this.loginData : this.loginAccessToken ? this.loginAccessToken : {}).then(({isValid}) => {
        if (isValid) {
          if (this.isRemoveDevice && this.device) {
            this.removeDevice();
          } else {
            this.dialogService.closeDialog('verification-input', {verified: true});
          }
        } else {
          this.snackbarControl.openErrorSnackbar(`http_error.0`);
        }
        this.uiLoader.stopUiLoader('verify-code');
      }).catch((e) => {
        this.snackbarControl.openHttpErrorSnackbar(e.code);
        this.uiLoader.stopUiLoader('verify-code');
      });
    }
  }

  resendVerificationCode() {
    if (this.canSend) {
      this.startTimer(this.maxTime);
      this.uiLoader.startUiLoader('send-pin');
      this.authV2Service.sendPinCode('verification-dialog', true).then(({result}) => {
        if (!result) {
          this.snackbarControl.openErrorSnackbar(`http_error.0`);
        }
        this.uiLoader.stopUiLoader('send-pin');
      }).catch((e) => {
        this.uiLoader.stopUiLoader('send-pin');
        this.snackbarControl.openHttpErrorSnackbar(e.code);
      });
    }
  }

  private removeDevice() {
    if (this.device) {
      this.uiLoader.startUiLoader('remove-device');
      this.authV2Service.removeUserDevice(this.device,
        this.loginData ? this.loginData : this.loginAccessToken ? this.loginAccessToken : {}).then(({result}) => {
        if (result) {
          this.dialogService.closeDialog('verification-input', {deviceRemoved: true});
          this.amplitudeService.deviceKnockoutEvent();
        } else {
          this.snackbarControl.openErrorSnackbar(`http_error.0`);
        }
        this.uiLoader.stopUiLoader('remove-device');
      }).catch((e) => {
        this.uiLoader.stopUiLoader('remove-device');
        this.snackbarControl.openHttpErrorSnackbar(e.code);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }

}
