import {Injectable} from '@angular/core';
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {IAdSourceType, IConversionSource, IConversionSourceHttp} from '@interfaces/marketing/marketing.interface';
import {HTTPApiURLs} from "@interfaces/common/http.interface";
import {IAdOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';

@Injectable({
  providedIn: 'root'
})
export class MarketingHttpService {

  private services = {
    deepLinking: 'deepLinking/api',
  };

  constructor(private customHttpService: CustomHttpService) { }

  getAdUSerOrder(sender: string): Promise<IAdOrderDetails> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.deepLinking}/getUserAdOrder`,
      sender: sender,
      receiver: 'getUserAdOrder',
      body: {}
    });
  }

  getUserAd(sender: string): Promise<IConversionSourceHttp> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.deepLinking}/getUserAd`,
      sender: sender,
      receiver: 'get-UserAd',
      body: {}
    });
  }

  addUserFromAd(sender: string, adSourceType: IAdSourceType, adSourceId: string): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: `${this.services.deepLinking}/addUserAd`,
      sender: sender,
      receiver: 'addUserAd',
      body: {
        adSourceId,
        adSourceType
      }
    })
  }
}
