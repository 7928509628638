import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateConfig} from '@services/language/ngx-translate.config';
import {NgxTranslateCutModule} from 'ngx-translate-cut';
import {VerifyEmailDialogModule} from '@login/modules/verify-email-dialog/verify-email-dialog.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorSncakBarModule} from '@components/error-sncak-bar/error-sncak-bar.module';
import {InfoSnackBarModule} from '@components/info-snack-bar/info-snack-bar.module';
import {ToastrModule} from 'ngx-toastr';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {environment} from '@environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {AngularFireModule} from '@angular/fire';
import {DeviceManagementDialogModule} from '@components/device-management-dialog/device-management-dialog.module';
import {VerificationInputDialogModule} from '@components/verification-input-dialog/verification-input-dialog.module';
import {AddEmailDialogModule} from '@components/add-email-dialog/add-email-dialog.module';
import {FooterModule} from '@components/footer/footer.module';
import {UiLoaderModule} from '@components/ui-loader/ui-loader.module';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {SimplifiedHeaderModule} from '@components/simplified-header/simplified-header.module';
import {SimplifiedFooterModule} from '@components/simplified-footer/simplified-footer.module';
import {AuthDialogContainerModule} from '@components/auth-dialog-container/auth-dialog-container.module';
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {CookieModule} from 'ngx-cookie';

function beforeAppInitFactory(connector: ConnectorV2Service) {
  return () => {
    connector.initConnector();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(TranslateConfig),
    NgxTranslateCutModule.forRoot(),
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      enableHtml: true,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: true,
      maxOpened: 4,
    }),
    CookieModule.forRoot(),
    BrowserTransferStateModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    VerifyEmailDialogModule,
    MatSnackBarModule,
    ErrorSncakBarModule,
    InfoSnackBarModule,
    HttpClientModule,
    DeviceManagementDialogModule,
    VerificationInputDialogModule,
    AddEmailDialogModule,
    FooterModule,
    UiLoaderModule,
    SimplifiedHeaderModule,
    SimplifiedFooterModule,
    AuthDialogContainerModule
  ],
  providers: [
    CustomHttpService,
    {
      provide: APP_INITIALIZER,
      useFactory: beforeAppInitFactory,
      deps: [ConnectorV2Service],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
