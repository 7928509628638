<div class="dialog-add-email-container" mat-dialog-content>
  <div class="dialog-add-email-content">
    <div class="add-email-container mat-form-field">
      <div class="email-img"></div>
      <h2>{{'add_email.title' | translate}}</h2>
      <p>{{'add_email.subtitle' | translate}}</p>
      <mat-form-field class="form-group with-prefix" appearance="outline">
        <input matInput type="email" name="email" maxlength="64"
               placeholder="{{'add_email.placeholder' | translate}}"
               class="form-control" [formControl]="emailField">
        <div matPrefix class="prefix-icon">
          <svg width="22" height="18">
            <use href="assets/images/sprite.svg#email"/>
          </svg>
        </div>
        <mat-error *ngIf="getFormFieldError(emailField) as message">
          {{'add_email.email_errors' | translate | translateCut : message - 1}}
        </mat-error>
      </mat-form-field>
      <button class="btn btn-danger" [disabled]="emailField.invalid" (click)="onConfirm()">
        {{'add_email.next_btn' | translate}}
      </button>
    </div>
  </div>
</div>
