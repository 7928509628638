import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {Router} from "@angular/router";
import {ELanguage, ETheme, EUserType, IConnectorUserInfo, IMessageUser} from '@interfaces/common/connector.interface';
import {FirebaseFirestorService} from "@services/firebase/firebase-firestor.service";
import {skip} from "rxjs/operators";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {environment} from '@environments/environment';
import {CookieService} from 'ngx-cookie';
import {EncryptionService} from '@services/request-token/encryption.service';

interface IMessage {
  type: 'lang' | 'theme' | 'user' | 'loaded',
  process: 'SET' | 'GET',
  value?: ELanguage | ETheme | IMessageUser
}

const USER_PREFERENCES_COOKIE_KEY = 'alm-user'

@Injectable({
  providedIn: 'root'
})
export class ConnectorV2Service {
  private isConnectorLoaded: boolean = false;
  connectorLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  connectorLanguage: BehaviorSubject<ELanguage> = new BehaviorSubject<ELanguage>(ELanguage.AR);
  connectorTheme: BehaviorSubject<ETheme> = new BehaviorSubject<ETheme>(ETheme.Dark);
  connectorUser: BehaviorSubject<IMessageUser | undefined> = new BehaviorSubject<IMessageUser | undefined>(undefined);
  isCheckingAuthorization: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private router: Router,
              private firestoreService: FirebaseFirestorService,
              private cookieService: CookieService,
              private encryptionService: EncryptionService,
  ) {
    const localPreferences = this.getLocalPreferences();
    this.connectorTheme.next(localPreferences.theme);
    this.connectorLanguage.next(localPreferences.lang);
  }

  initConnector(): void {
    if (isPlatformServer(this.platformId)) {
      this.connectorLoaded.next(true);
      this.connectorLoaded.complete();
      return;
    }
    this.firestoreService.initFireStore().then((loaded) => {
      if (loaded) {
        this.initMessenger().then(initiated => {
          this.router.initialNavigation();
        });
      } else {
        this.router.initialNavigation();
        this.connectorLoaded.next(true);
        this.connectorLoaded.complete();
      }
    }).catch(() => {
      this.router.initialNavigation();
      this.connectorLoaded.next(true);
      this.connectorLoaded.complete();
    });
  }


  private initMessenger(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.firestoreService.userInfo.pipe(skip(1)).subscribe((userInfo) => {
        this.setLocalPreferences(userInfo);
        this.onRemoteLanguageChange(userInfo.lang);
        this.onRemoteThemeChange(userInfo.theme);
        this.onRemoteUserChange(userInfo.user ?? undefined);
        if (!this.isConnectorLoaded) {
          this.isConnectorLoaded = true;
          resolve(true);
        }
      });
    });
  }

  private onRemoteLanguageChange(language: ELanguage) {
    if (language === ELanguage.AR || language === ELanguage.EN) {
      if (language !== this.connectorLanguage.getValue()) {
        this.connectorLanguage.next(language);
      }
    }
  }

  private onRemoteThemeChange(theme: ETheme) {
    if (theme === ETheme.Dark || theme === ETheme.Light) {
      if (theme !== this.connectorTheme.getValue()) {
        this.connectorTheme.next(theme);
      }
    }
  }

  private onRemoteUserChange(user?: IMessageUser) {
    if (user?.uuid && (user?.type === EUserType.B2C || (user?.type === EUserType.B2B && user?.link))) {
      if (user.uuid !== this.connectorUser.getValue()?.uuid) {
        this.connectorUser.next(user);
      }
    } else {
      this.connectorUser.next(undefined);
    }
  }

  changeRemoteLanguage(language: ELanguage) {
    if (language === ELanguage.AR || language === ELanguage.EN) {
      this.firestoreService.updateLanguage(language);
    }
  }

  changeRemoteTheme(theme: ETheme) {
    if (theme === ETheme.Dark || theme === ETheme.Light) {
      this.firestoreService.updateTheme(theme);
    }
  }

  addUser(user: IMessageUser): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (user.uuid && (user.type === EUserType.B2C || (user.type === EUserType.B2B && user.link))) {
        this.firestoreService.updateUser(user).then(resolve).catch(() => {resolve(false)});
      } else {
        resolve(false);
      }
    });
  }

  removeUser() {
    this.removeCookieUser();
    this.firestoreService.updateUser(null);
  }

  private setLocalPreferences(userInfo?: IConnectorUserInfo) {
    if (!userInfo) return;
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    if (isPlatformBrowser(this.platformId)) {
      const userInfoCookie: Partial<IConnectorUserInfo>[] = [
        {lang : userInfo?.lang},
        {theme: userInfo?.theme},
        {user: userInfo?.user},
      ];
      const userInfoBase64 = this.encryptionService.encodeStringToBase64(JSON.stringify(userInfoCookie));
      this.cookieService.put(USER_PREFERENCES_COOKIE_KEY, userInfoBase64, {
        domain: environment.platforms.base.link,
        path: '/',
        expires: cookieDate,
        sameSite: false,
        secure: true,
      });
    }
  }
  getLocalPreferences(): IConnectorUserInfo {
    const defaultPreferences: IConnectorUserInfo = {
      lang: ELanguage.AR,
      theme: ETheme.Dark,
      user: null
    };
    const cookie = this.cookieService.get(USER_PREFERENCES_COOKIE_KEY);
    if (cookie) {
      const userInfoString = this.encryptionService.decodeBase64ToString(cookie);
      const userInfoCookie: IConnectorUserInfo[] | IConnectorUserInfo = JSON.parse(userInfoString);
      if (userInfoCookie instanceof Array) {
        return  userInfoCookie.reduce((userInfo, current) => {
          userInfo = {...userInfo, ...current};
          return userInfo
        }, defaultPreferences);
      } else { // support old users who own cookie contain object instead of array
        return userInfoCookie;
      }
    } else {
      return defaultPreferences;
    }
  }

  private removeCookieUser() {
    this.cookieService.remove(USER_PREFERENCES_COOKIE_KEY, {
      domain: environment.platforms.base.link,
      path: '/',
      sameSite: "none",
      secure: true,
    })
  }
}
