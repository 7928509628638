import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VerifyEmailDialogComponent} from './verify-email-dialog.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {MatDialogModule} from "@angular/material/dialog";
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {TranslateModule} from "@ngx-translate/core";
import {NgxTranslateCutModule} from "ngx-translate-cut";


@NgModule({
  declarations: [VerifyEmailDialogComponent, VerifyEmailComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    NgxTranslateCutModule
  ],
  providers: [DialogService]
})
export class VerifyEmailDialogModule {
}
