import { Injectable } from '@angular/core';
import {IOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {SubscriptionMarketingService} from '@services/marketing/subscription-marketing.service';
import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';
import {IPaymentBase} from "@interfaces/payment/payment.interface";

@Injectable({
  providedIn: 'root'
})
export class BrazeSubscriptionEventsService {

  constructor(
    private brazeService: BrazeService,
    private subscriptionMarketingService: SubscriptionMarketingService,
  ) { }

  logSubscriptionCheckout({ paymentObject, couponCode }: { paymentObject: IPaymentBase, couponCode: string | undefined }) {
    this.brazeService.logEvent('subscription_checkout', {
      plan: this.subscriptionMarketingService.getSubscriptionPlanName(paymentObject.items[0].itemId),
      payment_method: PaymentMethods[paymentObject.paymentMethod as PaymentMethods],
      discount_code: couponCode,
      amount_after_discount: paymentObject.finalAmountUSD,
    });
  }

  successPayment(order: IOrderDetails): void {
    const  item = {
      id: order.items[0].item.id,
      name: this.subscriptionMarketingService.getSubscriptionPlanName(order.items[0].item.id),
      discountAmount: order.items[0]?.discountItems?.length ? order.items[0]?.discountItems[0].discountAmount : undefined,
    };
    const paymentMethod: IPaymentMethod = PaymentMethods[order.paymentMethod] as IPaymentMethod;
    const isMonthlyPlan = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19].includes(item.id);
    const isYearlyPlan = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20].includes(item.id);
    if (isMonthlyPlan) {
      this.brazeService.logEvent('monthly_subscriber', {
        method: paymentMethod,
        date: new Date(order.date)
      });
    } else if (isYearlyPlan) {
      this.brazeService.logEvent('yearly_subscriber', {
        method: paymentMethod,
        date: new Date(order.date)
      });
    }

    this.brazeService.logEvent('payment_method', {
      method: paymentMethod
    });

    this.brazeService.logPurchase({
      productId: item.name,
      price: order.finalPrice,
      currencyCode: order.currency,
      quantity: 1,
      purchaseProperties: {
        method: paymentMethod,
        discountAmount: item.discountAmount
      }
    });
    this.brazeService.setCustomUserAttribute('subscribed', true);
    this.brazeService.setCustomUserAttribute('subscription_start_date', new Date(order.date));
    let expirationDate = order.expirationDate;
    if (!expirationDate) {
      const planDate = new Date(order.date);
      if (isMonthlyPlan) {
        planDate.setMonth(planDate.getMonth() + 1);
        planDate.setDate(planDate.getDate() - 1);
      } else if (isYearlyPlan) {
        planDate.setFullYear(planDate.getFullYear() + 1);
        planDate.setDate(planDate.getDate() - 1);
      }
      expirationDate = planDate.toISOString();
    }
    this.brazeService.setCustomUserAttribute('subscription_end_date', new Date(expirationDate));
  }
}
