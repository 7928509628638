import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IEmailSignup} from '@interfaces/common/auth.interface';
import {HTTPApiURLs} from '@interfaces/common/http.interface';
import {
  IActivationResponse,
  IChangePasswordPayload,
  IFacebookLoginInfoResponse, IFacebookLoginResponse, IGoogleLoginResponse,
  ILoginResponse
} from '@interfaces/user/user-common.interface';
import {IDevice} from '@interfaces/common/device.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {IAuthorizedResponse} from "@services/integrations/auth-v2/auth-v2.service";

@Injectable({
  providedIn: 'root'
})
export class AuthHttpV2Service {

  private ssoPath: string = 'SSO/api/sso/';
  private authorizePath: string = 'authorize/api/sso/';

  constructor(private customHttpService: CustomHttpService) {
  }

  emailSingUp(sender: string, signUpForm: IEmailSignup): Promise<any> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'signup',
      sender: sender,
      receiver: 'signup',
      body: signUpForm,
    });
  }

  activateUser(sender: string, v: number, g: string): Promise<IActivationResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'signup-activate',
      sender: sender,
      receiver: 'active',
      body: {v: v, g: g},
    });
  }

  resendEmail(sender: string, email: string, password: string): Promise<any> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'resend-activation-mail',
      sender: sender,
      receiver: 'mail',
      body: {email: email, password: password},
    });
  }

  b2cEmailLogin(sender: string, email: string, password: string, platform: string | null, subDomain: string | null): Promise<ILoginResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'login',
      sender: sender,
      receiver: 'login',
      body: {email: email, password: password, platform: platform, subDomain: subDomain},
    });
  }

  forgetPassword(email: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'send-forget-password-mail',
      sender: 'forget-password',
      receiver: 'send-forget-password-mail',
      body: {
        email: email
      },
    });
  }

  resetPassword(resetPasswordBody: { a: string, s: string, newPassword: string }): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'save-forget-password',
      sender: 'reset-password',
      receiver: 'save-forget-password',
      body: resetPasswordBody,
    });
  }

  getFacebookInfo(sender: string, accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'user-fb-info',
      sender: sender,
      receiver: 'user-fb-info',
      body: {accessToken: accessToken},
    });
  }

  facebookLogin(sender: string, facebookInfo: IFacebookLoginInfoResponse, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'facebooklogin',
      sender: sender,
      receiver: 'facebooklogin',
      body: {platform: platform, ...facebookInfo},
    });
  }

  googleLogin(sender: string, accessToken: string, platform: string | null, subDomain: string | null): Promise<ILoginResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'googlelogin',
      sender: sender,
      receiver: 'googlelogin',
      body: {accessToken: accessToken, platform: platform},
    });
  }

  googleLoginWithCode(sender: string, code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'googlelogin-byCode',
      sender: sender,
      receiver: 'googlelogin-byCode',
      body: {code: code, redirectUrl: redirectUrl, platform: platform, subDomain: subDomain},
    });
  }

  getUserDevices(userInfo: { email: string, password: string, userType: number } | {}): Promise<IDevice[]> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'user-devices',
      sender: 'devices',
      receiver: 'user-devices',
      body: userInfo,
    });
  }

  sendPinCode(sender: string, sendPinCodeObject: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'send-pin-code',
      sender: sender,
      receiver: 'send-pin-code',
      body: sendPinCodeObject,
    })
  }

  verifyEmailPinCode(sender: string, activationObject: {
    pinCode: number,
    userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}
  }): Promise<{ isValid: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'verify-pin-code',
      sender: sender,
      receiver: 'verify-pin-code',
      body: activationObject,
    });
  }

  removeUserDevice(removeDevice: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'remove-device',
      sender: 'devices',
      receiver: 'remove-device',
      body: removeDevice,
    });
  }

  authorize(sender: string, platform: string|null, subDomain: string|null): Promise<IAuthorizedResponse> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.authorizePath+'authorize',
      sender: sender,
      receiver: 'authorize',
      body: {platform: platform, subDomain: subDomain},
    });
  }

  addNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'add-notification-token',
      sender: sender,
      receiver: 'add-notification-token',
      body: {notificationToken: token},
    });
  }

  removeNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'remove-notification-token',
      sender: sender,
      receiver: 'add-notification-token',
      body: {notificationToken: token},
    });
  }

  logoutUser(sender: string, notificationToken: string | null): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'logout',
      sender: sender,
      receiver: 'logout',
      body: {deviceId: notificationToken},
    });
  }

  getUserInfo(sender: string): Promise<IUserInfo> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'GetUserInfo',
      sender: sender,
      receiver: 'logout',
      body: {},
    });
  }

  changeUserPassword(sender: string, payload: IChangePasswordPayload): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      baseUrl: HTTPApiURLs.NewOrch,
      endpoint: this.ssoPath+'change/password',
      sender: sender,
      receiver: 'changePassword',
      body: payload
    });
  }
}
