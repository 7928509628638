export interface IHttpResponse {
  success: boolean;
  data: any;
  error: IHttpError;
}

export interface IHttpError {
  code: number;
  message: string;
  status: number;
}

export enum HTTPApiURLs{
  JavaOrch = 'javaOrch',
  SsoOrch = 'ssoOrch',
  NewOrch = 'newOrch',
  B2BOrch = 'b2bOrch',
  AdminOrch = 'adminOrch',
  SelfEnrollOrch = 'selfEnrollOrch'
}
