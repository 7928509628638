import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpContainerComponent } from './sign-up-container.component';
import { SocialSignUpComponent } from './social-sign-up/social-sign-up.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import {SocialButtonsModule} from '@components/social-buttons/social-buttons.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {NgxTranslateCutModule} from 'ngx-translate-cut';
import {PasswordCheckerModule} from '@components/password-checker/password-checker.module';



@NgModule({
    declarations: [SignUpContainerComponent, SocialSignUpComponent, SignUpFormComponent],
  exports: [
    SignUpContainerComponent,
    SocialSignUpComponent,
    SignUpFormComponent
  ],
  imports: [
    CommonModule,
    SocialButtonsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    TranslateModule,
    NgxTranslateCutModule,
    PasswordCheckerModule
  ]
})
export class SignUpContainerModule { }
