<div class="dialog-verification-input-container" mat-dialog-content>
  <div class="dialog-verification-input-header">
    <button type="button" class="dialog-verification-input-header-close-btn" (click)="closeDialog()">
      <svg aria-hidden="true">
        <use href="assets/images/sprite.svg#times"/>
      </svg>
    </button>
  </div>
  <div class="dialog-verification-input-content">
    <div class="verification-input-activation-form">
      <div class="verification-input-activation-form_header">
        <p class="verification-input-activation-form_header-text">
          {{'verification_input_dialog_activation_number.text' | translate}}
        </p>
      </div>
      <div class="verification-input-activation-form_fields">
        <alm-root-otp-input (formStatus)="setDisableButtonState($event)"
                            (verificationCode)="setVerificationCode($event)"
                            (onSubmit)="setVerificationCode($event);"
        ></alm-root-otp-input>
      </div>
      <div class="verification-input-activation-form_actions">
        <div class="verification-input-activation-form_actions__verify">
          <button [disabled]="disableButton" class="btn btn-danger"
                  (click)="verifyCode()">
            {{'verification_input_dialog_activation_number.confirm' | translate}}
          </button>
        </div>
        <div class="verification-input-activation-form_actions__footer">
          {{'verification_input_dialog_activation_number.no_code_received' | translate | translateCut: 0}}
          <button *ngIf="canSend" class="font-weight-bold btn btn-link link-underline resend-email-button"
                  (click)="resendVerificationCode()">
            {{'verification_input_dialog_activation_number.no_code_received' | translate | translateCut: 1}}
          </button>
          <span *ngIf="!canSend">{{timer}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
