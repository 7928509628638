import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemographicsComponent } from './demographics.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {NgxTranslateCutModule} from 'ngx-translate-cut';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {PhoneInputModule} from "@components/phone-input/phone-input.module";



@NgModule({
    declarations: [DemographicsComponent],
    exports: [
        DemographicsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        TranslateModule,
        NgxTranslateCutModule,
        PhoneInputModule
    ]
})
export class DemographicsModule { }
