import { Injectable } from '@angular/core';
import mixpanel, {Callback, Dict, Mixpanel, RequestOptions} from 'mixpanel-browser';
import {environment} from "@environments/environment";
import {IUserInfo} from "@interfaces/authorized-user/user.interface";
import {ITrackingOptions, TrackingEvent} from "@services/tracking/tracking.interface";

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  initialized: boolean = false;
  eventQueue: TrackingEvent[] = [];
  constructor() { }

  initMixpanel() {
    setTimeout(() => {
      mixpanel.init(environment.mixpanel.token, {
        debug: environment.mixpanel.debug,
        track_pageview: true,
        ignore_dnt: true,
        persistence: 'cookie',
        cookie_domain: environment.platforms.base.link,
        cross_subdomain_cookie: true,
        loaded: (mixpanel => {
          this.initialized = true
          this.flushQueue(this.eventQueue);
        })
      });
    }, 1000);
  }

  private setUserId(userUUID: string) {
    if (!this.initialized) return;
    mixpanel.identify(userUUID);
    mixpanel.identify(userUUID);
  }

  private setProfile(user: IUserInfo) {
    if (!this.initialized) return;
    mixpanel.people.set({
      "$name": user.name,
      "$email": user.email,
    })
  }

  setUser(user: IUserInfo) {
    if (!this.initialized) return;
    this.setUserId(user.uuid);
    this.setProfile(user);
  }

  track(
    trackingEvent: TrackingEvent,
    options?: ITrackingOptions,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ): void {
    if (!this.initialized) return this.addToQueue(trackingEvent)
    // @ts-ignore
    //mixpanel.push(['track', trackingEvent.name, trackingEvent.properties] )
    mixpanel.track(
      trackingEvent.name,
      trackingEvent.properties,
      optionsOrCallback,
      callback
      );
    if (options?.navigationEvent) {
      // immediate flush for navigational events
      // to send the event as fast as we can before navigating to another website
      // we use ts-ignore as the flush is not in the mixpanel interface
      // @ts-ignore
      mixpanel.request_batchers.events.flush();
    }

  }

  private addToQueue(trackingEvent: TrackingEvent) {
    this.eventQueue.push(trackingEvent);
  }
  private flushQueue(eventQueue: TrackingEvent[]) {
    while (eventQueue.length) {
      // @ts-ignore
      const event: TrackingEvent = this.eventQueue.shift();
      this.track(event);
    }
  }
}
