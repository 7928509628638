import {Injectable} from '@angular/core';
import {DynamicLoadService} from '@services/dynamic-load/dynamic-load.service';
import {NavigationEnd, Router} from '@angular/router';
import {IPaymentMethod} from '@interfaces/common/payment.interface';
import {ISignupData} from "@interfaces/user/user-common.interface";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";
import {EncryptionService} from "@services/request-token/encryption.service";

declare var dataLayer: any;

enum PaymentStatus {
  SUCCESS = 1,
  FAILED,
  PENDING
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  private dataLayer?: any[];

  constructor(private dynamicLoad: DynamicLoadService, private router: Router,
              private encryptionService: EncryptionService) {
  }

  loadGtm(id: string): void {
    this.dynamicLoad.externalScript('gtm-script',
      `https://www.googletagmanager.com/gtm.js?id=${id}&l=dataLayer`).then((loaded) => {
      if (loaded) {
        (<any> window).dataLayer = (<any> window).dataLayer || [];
        this.dataLayer = (<any> window).dataLayer;
        this.sendCustomEvent('gtm.js', {'gtm.start': new Date().getTime()});
        this.sendPageView();
      }
    });
  }

  sendCustomEvent(eventName: string, options?: {}) {
    if (eventName && this.dataLayer) {
      options = options ? options : {};
      this.dataLayer.push({ecommerce: null});
      this.dataLayer.push({
        event: eventName,
        ...options
      });
    }
  }

  sendPageView(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sendCustomEvent('page', {'pageName': event.url});
      }
    });
  }

  sendLoginEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed', social?: 'FACEBOOK' | 'GOOGLE' }) {
    this.sendCustomEvent('LOGIN', options);
  }

  sendSignupEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed' }) {
    this.sendCustomEvent('SIGN-UP', options);
  }

  sendUserUUID(userUUID: string | null) {
    this.sendCustomEvent('SET-USER-ID', {userId: userUUID});
  }

  addToCartEvent(options: { from: string, item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('ADD-TO-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        add: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            list_name: options.from,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  removeFromCartEvent(options: { item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('REMOVE-FROM-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        remove: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  addToSaveListEvent(options: { from: string, item: { id: number, name: string } }) {
    this.sendCustomEvent('ADD-TO-SAVE-LIST', options);
  }

  removeFromSaveListEvent(options: { item: { id: number, name: string } }) {
    this.sendCustomEvent('REMOVE-FROM-SAVE-LIST', options);
  }

  // checkOutEvent(options: {
  //   type: 'OTP' | 'SUBSCRIPTION',
  //   items: { id: number, name: string, price: number, coupon?: string }[]
  // }) {
  //   let items: any[] = GoogleTagManagerService.prepareCheckoutItems(options.items, options.type);
  //   this.sendCustomEvent(`CHECKOUT${options.type == 'SUBSCRIPTION' ? '-SUBSCRIPTION' : ''}`, {
  //     ecommerce: {
  //       checkout: {
  //         actionField: {step: 1},
  //         products: items
  //       }
  //     }
  //   })
  // }

  subscriptionCheckOutEvent(options: {
    method: IPaymentMethod,
    item: { id: number, name: string, price: number, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('CHECKOUT-SUBSCRIPTION', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      ecommerce: {
        checkout: {
          actionField: {step: 1, options: options.method},
          products: items
        }
      }
    });
  }

  // paymentStatusEvent(options: {
  //   type: 'OTP' | 'SUBSCRIPTION', trn: string, price: number, status: PaymentStatus,
  //   items: { id: number, name: string, coupon?: string }[]
  // }) {
  //   let items: any[] = GoogleTagManagerService.prepareCheckoutItems(options.items, options.type);
  //   this.sendCustomEvent(`PAYMENT-STATUS${options.type == 'SUBSCRIPTION' ? '-SUBSCRIPTION' : ''}`, {
  //     ecommerce: {
  //       purchase: {
  //         actionField: {
  //           id: options.trn,
  //           revenue: options.price,
  //           tax: 0,
  //           shipping: 0,
  //           status: options.status
  //         },
  //         products: items
  //       }
  //     }
  //   })
  // }

  successfulPaymentStatus(options: {
    trn: string,
    price: number,
    currency: string
    discount: number,
    item: { id: number, name: string, coupon?: string },
    method: IPaymentMethod
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-SUCCESS', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Success Subscription',
      action: options.trn,
      label: options.item.name,
      value: options.price,
      // Google ecommerce details
      ecommerce: {
        transaction_id: options.trn,
        value: options.price,
        currency: "USD",
        coupon: options.item.coupon,
        items: [{
          item_id: options.item.id,
          item_name: options.item.name,
          price: options.price,
          coupon: options.item.coupon,
          discount: options.discount,
        }]
      }
    });
  }

  pendingPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: number, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-PENDING', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Pending Subscription',
      action: options.method,
      label: options.trn
    });
  }

  failedPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: number, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-FAILED', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Failed Subscriptions',
      action: options.method,
      label: options.trn
    });
  }

  goToSubscriptionPageEvent(options: { from: string, type: 'HOT' | 'COLD' }) {
    this.sendCustomEvent('GO-SUBSCRIPTION', options);
  }

  subscriptionStatusChangeEvent(options: { type: 'CHANGE' | 'CANCEL', oldPlan: number, newPlan: number }) {
    this.sendCustomEvent('SUBSCRIPTION-CHANGE', options);
  }

  courseBehavioursEvent(options: { type: 'PROMO' | 'INTRO' | 'SHARE', item: { id: number, name: string } }) {
    this.sendCustomEvent(`COURSE-${options.type}`, options.item);
  }

  private prepareCheckoutItems(item: { id: number, name: string, price?: number, coupon?: string }): any {
    let items: any[] = [];
    items.push({
      id: item.id,
      name: item.name,
      list_name: 'checkout-subscription',
      quantity: 1,
      price: item.price,
      coupon: item.coupon ? item.coupon : ''
    });
    return items;
  }

  // private prepareCheckoutItems(array: { id: number, name: string, price?: number, coupon?: string }[], type: 'OTP' | 'SUBSCRIPTION'): any {
  //   let items: any[] = [];
  //   array.map((item) => {
  //     items.push({
  //       id: item.id,
  //       name: item.name,
  //       list_name: 'checkout-subscription',
  //       quantity: 1,
  //       price: item.price,
  //       coupon: item.coupon ? item.coupon : ''
  //     })
  //   });
  //   return items;
  // }
  sendSocialLoginEvent(activateData: ISignupData) {
    const normalizeAndHash = (string?: string) => {
      if (string) {
        return this.encryptionService.hashStringWithSHA256(string.trim().toLowerCase())
      }
      return undefined;
    }
    this.sendCustomEvent('SOCIAL-LOGIN', {
      name: normalizeAndHash(activateData.userName),
      email: normalizeAndHash(activateData.email),
    });
  }
}
