import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ILanguage, ILanguageCode} from '@interfaces/common/language.interface';
import {BehaviorSubject, Observable} from "rxjs";
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ConnectorV2Service} from "@services/connector/connector-v2.service";

enum ELanguage {
  AR = 'ar',
  EN = 'en'
}

const defaultLanguages: ILanguage[] = [
  {
    id: 1,
    name: "English",
    code: "en",
    direction: "ltr"
  },
  {
    id: 2,
    name: "العربية",
    code: "ar",
    direction: "rtl"
  }
];

@Injectable({
  providedIn: 'root'
})
export class LanguageControlService {

  private languageList: ILanguage[] = [];
  private renderer: Renderer2;
  currentLanguage: BehaviorSubject<ILanguage> = new BehaviorSubject<ILanguage>(defaultLanguages[1]);

  constructor(private translateService: TranslateService,
              private rendererFactory: RendererFactory2,
              private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject(DOCUMENT) private document: Document
              ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.getAllLanguages().then((languages: ILanguage[]) => {
      this.languageList = languages;
      this.translateService.addLangs(this.getLanguagesCodes());
      this.initialDefaultLanguage();
    });
  }

  private initialDefaultLanguage(): void {
    let prevLang = this.connectorService.connectorLanguage.getValue();
    this.connectorService.connectorLanguage.subscribe((language) => {
      prevLang = language;
      let userLocalLang = language === "en" ? "en" : "ar";
      let languages = this.translateService.getLangs();
      let currentLang = 'ar';
      if (languages.includes(<string>userLocalLang)) {
        currentLang = <string>userLocalLang;
      } else {
        let browserLang = this.translateService.getBrowserLang();
        if (languages.includes(browserLang)) {
          currentLang = <string>browserLang;
        }
      }
      this.changeLanguage(currentLang);
    });
  }

  private getLanguage(lang: string): ILanguage {
    return this.languageList.filter(language => {
      return language.code == lang;
    })[0];
  }

  private getLanguagesCodes(): string[] {
    return this.languageList.map(language => {
      return language.code;
    });
  }

  private changeWebsiteDirection(direction: 'rtl' | 'ltr'): void {
      this.renderer.setAttribute(this.document.body, 'dir', direction);
  }

  getCurrentLanguage(): ILanguage {
    return this.currentLanguage.getValue()
  }

  getAllLanguages(): Promise<ILanguage[]> {
    return new Promise<ILanguage[]>(resolve => {
      if (this.languageList.length > 0)
        resolve(this.languageList);
      else
        resolve(defaultLanguages);
    });
  }

  getLanguageName(lang: number): ILanguage {
    return this.languageList.filter(language => {
      return language.id == lang;
    })[0];
  }

  setTranslations(lang: ILanguageCode, translations: Object) {
    this.translateService.setTranslation(lang, translations, true);
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    let currentLang = this.getLanguage(lang);
    this.changeWebsiteDirection(currentLang.direction);
    if (lang !== this.currentLanguage.getValue().code) {
      this.currentLanguage.next(currentLang);
      this.connectorService.changeRemoteLanguage(<ELanguage>lang);
    }
  }

  translate(key: string): Observable<string> {
    return this.translateService.get(key);
  }
}
