import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "@services/integrations/user/user.service";
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {UserManagerService} from "@services/user/user-manager.service";
import {environment} from '@environments/environment';
import {IEnvironmentPlatforms, IUserPlatform} from '@interfaces/user/user-common.interface';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {
  environmentPlatforms: { [key: string]: IEnvironmentPlatforms } = environment.platforms;

  constructor(private userManagerService: UserManagerService,
              private userService: UserService,
              private router: Router,
              private appRedirects: AppRedirectsService,
              private uiLoader: UiLoaderService,
              private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  private checkUserAuth(state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let localUser = this.connectorService.connectorUser.getValue();
      if (localUser?.uuid) {
        this.userService.getUserPlatforms().then(platforms => {
          if (platforms.length === 1) {
            this.redirectToPlatform(platforms[0]);
          } else {
            this.uiLoader.startUiLoader('login-auth-guard');
            this.router.navigate(['/redirect-center'], {queryParams: {'prevError': '001'}}).then(() => {
              this.uiLoader.stopUiLoader('login-auth-guard');
            });
          }
        }).catch((e) => {
          this.loadConnector();
          resolve(true);
        });
      } else {
        this.loadConnector();
        resolve(true);
      }
    });
  }

  private loadConnector() {
    this.connectorService.connectorLoaded.next(true);
    this.connectorService.connectorLoaded.complete();
  }

  private redirectToPlatform(platform: IUserPlatform, path?: string) {
    this.uiLoader.startUiLoader('outside-loader');
    let platformUrl;
    let baseUrl = environment.platforms.base.link;
    const standardPlatform = Object.values<IEnvironmentPlatforms>(environment.platforms).find(p => p.code === platform.platform);
    if (standardPlatform) {
      platformUrl = `${standardPlatform.link}`;
    } else {
      platformUrl = `https://${platform.domain}.${baseUrl}`;
    }
    if (isPlatformBrowser(this.platformId)) {
      window.location.replace(platformUrl + (path || ''));
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserAuth(state);
  }

}
