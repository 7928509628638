import {Injectable} from '@angular/core';
import {IUsp} from "@interfaces/usp.interface";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionMarketingService {

  constructor() {
  }

  subscriptionPlans: { [key: number]: { id: number, name: string } } = {
    1: {id: 1, name: 'Egypt Monthly Plan'},
    2: {id: 2, name: 'Egypt Yearly Plan'},
    3: {id: 3, name: 'Rest of countries Monthly Plan'},
    4: {id: 4, name: 'Rest of countries Yearly Plan'},
    5: {id: 5, name: 'UAE Monthly Plan'},
    6: {id: 6, name: 'UAE Yearly Plan'},
    7: {id: 7, name: 'Saudi Arabia Monthly Plan'},
    8: {id: 8, name: 'Saudi Arabia Yearly Plan'},
    9: {id: 9, name: 'Qatar Monthly Plan'},
    10: {id: 10, name: 'Qatar Yearly Plan'},
    11: {id: 11, name: 'Egypt Monthly Plan'},
    12: {id: 12, name: 'Egypt Yearly Plan'},
    13: {id: 13, name: 'UAE Monthly Plan'},
    14: {id: 14, name: 'UAE Yearly Plan'},
    15: {id: 15, name: 'Saudi Arabia Monthly Plan'},
    16: {id: 16, name: 'Saudi Arabia Yearly Plan'},
    17: {id: 17, name: 'Qatar Monthly Plan'},
    18: {id: 18, name: 'Qatar Yearly Plan'},
    19: {id: 19, name: 'Rest of countries Monthly Plan'},
    20: {id: 20, name: 'Rest of countries Yearly Plan'},
   };

  getSubscriptionPlanName(id: number): string {
    return this.subscriptionPlans[id]?.name || '';
  }
}
