import {APP_ID, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenService} from "@services/request-token/token.service";
import {HTTPApiURLs, IHttpResponse} from "@interfaces/common/http.interface";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {environment} from "@environments/environment";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {observable, Observable, Subscriber, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {LanguageControlService} from '@services/language/language-control.service';


interface IRequestOptions {
  apiURL?: string;
  endpoint: string;
  sender: string;
  receiver: string;
  body: object;
}

/**
 * A service to create http requests to the backend using custom token
 */
@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService, private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string,
              private state: TransferState,
              private languageControl: LanguageControlService
  ) {
  }

  private _platformCode: string | undefined;

  get platformCode(): string | undefined {
    return this._platformCode;
  }

  set platformCode(value: string | undefined) {
    this._platformCode = value;
  }

  /**
   * Check if user session is stored in cookies
   */
  private checkIfUserLoggedIn = (): string | undefined => {
    let user = this.connectorService.connectorUser.getValue();
    if (user) {
      let uuid = user.uuid;
      if (uuid) {
        return uuid;
      }
    }
    return undefined;
  }

  private setServiceUrl(baseUrl?: HTTPApiURLs): string {
    let serviceUrls = environment.serviceUrls;
    let apiBaseUrl = serviceUrls[HTTPApiURLs.JavaOrch];
    if (baseUrl) {
      if (serviceUrls.hasOwnProperty(baseUrl)) {
        apiBaseUrl = (<any>serviceUrls)[baseUrl];
      }
    }
    return apiBaseUrl;
  }

  processStateResponse(response: IHttpResponse, authCheck?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (response.success) {
        resolve(response.data);
      } else {
        if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
          // window.location.reload();
          reject(response.error);
        } else {
          reject(response.error);
        }
      }
    });
  }

  /**
   * Send a post request to backend with the custom token created by the Token Service
   * @param request
   * @param authCheck
   */
  sendRequest<T = any>(request: {
    endpoint: string,
    sender: string,
    receiver: string,
    body: Object,
    baseUrl?: HTTPApiURLs,
    skipOnSSR?: boolean
  }, authCheck?: boolean): Promise<any> {
    const API_STATE_KEY = makeStateKey<string>(`${this.languageControl.getCurrentLanguage().code}/${request.endpoint}`);
    if (isPlatformBrowser(this.platformId)) {
      const savedResponseState = this.state.get<string | undefined>(API_STATE_KEY, undefined);
      if (savedResponseState) {
        this.state.remove<IHttpResponse>(API_STATE_KEY);
        return this.processStateResponse(JSON.parse(savedResponseState), authCheck);
      }
    }
    return new Promise<T>((resolve, reject) => {
      if (isPlatformServer(this.platformId) && request.skipOnSSR) return reject();
      let apiBaseUrl = this.setServiceUrl(request.baseUrl);
      let uuid = this.checkIfUserLoggedIn();
      let platformCode = this.platformCode ?? null;
      let requestToken = this.tokenService.buildRequestToken({
        sender: request.sender,
        receiver: request.receiver,
        uuid: uuid,
        body: request.body,
        platform: platformCode
      });
      this.httpClient.post<T | IHttpResponse>(
        `${apiBaseUrl}/${request.endpoint}`,
        requestToken,
        {
          withCredentials: true,
        }
      ).toPromise()
        .then((response: T | IHttpResponse) => {
          if (isPlatformServer(this.platformId)) {
            this.state.set<string>(API_STATE_KEY, JSON.stringify(response));
          }
          // this type check as we start to return data on the root response object
          // So we check if old response it will have success as a boolean
          // in old endpoints we will return the response.data
          if ("success" in response) {
            if (response.success) {
              resolve(response.data);
            } else {
              reject(response.error);
            }
            // in new endpoints we will return the data from root response object
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendCancelableRequest(
    request: { endpoint: string, sender: string, receiver: string, body: Object, baseUrl?: HTTPApiURLs },
    authCheck?: boolean, skipPlatform?: boolean): { send: Promise<any>, cancel: () => void } {
    let subscription: Subscription;
    let promise: Promise<any> = new Promise<any>((resolve, reject) => {
      if (isPlatformBrowser(this.platformId) || skipPlatform) {
        let apiBaseUrl = this.setServiceUrl(request.baseUrl);
        let uuid = this.checkIfUserLoggedIn();
        let platformCode = this.platformCode ?? null;
        let requestToken = this.tokenService.buildRequestToken({
          sender: request.sender,
          receiver: request.receiver,
          uuid: uuid,
          body: request.body,
          platform: platformCode
        });

        subscription = this.httpClient.post<IHttpResponse>(`${apiBaseUrl}/${request.endpoint}`, requestToken,
          {withCredentials: true}).subscribe((response: IHttpResponse) => {
          if (response.success) {
            resolve(response.data);
          } else {
            if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
              // window.location.reload();
              reject(response.error);
            } else {
              reject(response.error);
            }
          }
          if (subscription) {
            subscription.unsubscribe();
          }
        }, (error) => {
          if (subscription) {
            subscription.unsubscribe();
          }
          reject(error);
        });
      } else {
        reject();
      }
    })

    return {
      send: promise,
      cancel: () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      }
    };
  }


  upload(request: {
    endpoint: string,
    sender: string,
    receiver: string,
    body: Object,
    file: File,
    baseUrl?: HTTPApiURLs
  }): Promise<any> {
    let apiBaseUrl = this.setServiceUrl(request.baseUrl);
    let uuid = this.checkIfUserLoggedIn();
    let platformCode = this.platformCode ?? null;
    let requestToken = this.tokenService.buildRequestToken({
      sender: request.sender,
      receiver: request.receiver,
      uuid: uuid,
      body: request.body,
      platform: platformCode
    });
    let fileFormData = new FormData();
    fileFormData.append('file', request.file, request.file.name);
    fileFormData.append('token', requestToken);
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post<IHttpResponse>(`${apiBaseUrl}/${request.endpoint}`, fileFormData,
        {withCredentials: true}).toPromise()
        .then((response: IHttpResponse) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendRequestWithoutToken<T>(request: {
    endpoint: string,
    sender: string,
    receiver: string,
    body: Object,
    baseUrl?: HTTPApiURLs
  }, authCheck?: boolean, skipPlatform?: boolean): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (isPlatformBrowser(this.platformId) || skipPlatform) {
        let apiBaseUrl = this.setServiceUrl(request.baseUrl);
        this.httpClient.post<IHttpResponse>(`${apiBaseUrl}/${request.endpoint}`, request.body,
          {withCredentials: true}).toPromise()
          .then((response: IHttpResponse) => {
            if (response.success) {
              resolve(response.data);
            } else {
              if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
                // window.location.reload();
                reject(response.error);
              } else {
                reject(response.error);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }
}

