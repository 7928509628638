import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {TranslateModule} from "@ngx-translate/core";
import {NgxTranslateCutModule} from "ngx-translate-cut";



@NgModule({
  declarations: [PhoneInputComponent],
  exports: [
    PhoneInputComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    TranslateModule,
    NgxTranslateCutModule
  ]
})
export class PhoneInputModule { }
