import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyEmailComponent } from './verify-email.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxTranslateCutModule} from 'ngx-translate-cut';



@NgModule({
    declarations: [VerifyEmailComponent],
    exports: [
        VerifyEmailComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxTranslateCutModule
  ]
})
export class VerifyEmailModule { }
