import { Injectable } from '@angular/core';
import {ITrackingOptions, TrackingEvent, TrackingEventName} from "@services/tracking/tracking.interface";
import {MixpanelService} from "@services/mixpanel/mixpanel.service";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private mixpanelService: MixpanelService) { }

  sendEvent(event: TrackingEvent, options?: ITrackingOptions) {
    console.log('send event:', event.name);
    this.mixpanelService.track(event, options);
  }

  sendSignUpStartedEvent(status: {email: string, status: boolean}) {
    this.sendEvent({
      name: TrackingEventName.SIGNUP_WITH_EMAIL_STARTED,
      properties: status
    });
  }

  signupActivationEvent(properties: { authentication_type: ELoginTypes; status: boolean }) {
    this.sendEvent({
      name: TrackingEventName.SIGNUP_ACTIVATION,
      properties
    });
  }

  loginSuccessfullyEvent(properties: { authentication_type: ELoginTypes}) {
    this.sendEvent({
      name: TrackingEventName.LOGIN_SUCCESSFULLY,
      properties
    });
  }
}
