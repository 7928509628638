import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ConnectorV2Service} from "@services/connector/connector-v2.service";

export enum THEMES {
  DARK,
  LIGHT
}

@Injectable({
  providedIn: 'root'
})
export class ThemeControlService {

  private renderer: Renderer2
  currentTheme: BehaviorSubject<THEMES> = new BehaviorSubject<THEMES>(THEMES.DARK);

  constructor(private rendererFactory: RendererFactory2, private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.initialDefaultTheme();
  }

  private initialDefaultTheme(): void {
    this.connectorService.connectorTheme.subscribe((theme) => {
      this.changeTheme(theme == 1 ? THEMES.LIGHT : THEMES.DARK);
    });
  }

  private applyDarkTheme(): void {
      this.renderer.removeClass(this.document.body, 'light');
      this.renderer.addClass(this.document.body, 'dark');
  }

  private applyLightTheme(): void {
      this.renderer.removeClass(this.document.body, 'dark');
      this.renderer.addClass(this.document.body, 'light');
  }

  changeTheme(theme: THEMES): void {
    theme == THEMES.LIGHT ? this.applyLightTheme() : this.applyDarkTheme();
    if(theme !== this.currentTheme.getValue()){
      this.currentTheme.next(theme);
      this.connectorService.changeRemoteTheme(<0 | 1>theme);
    }
  }

  applyPaymentTheme(): void {
    this.applyDarkTheme();
  }
}
