import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer.component';
import {MainFooterComponent} from './main-footer/main-footer.component';
import {SubFooterComponent} from './sub-footer/sub-footer.component';
import {FooterSocialLinksComponent} from './footer-social-links/footer-social-links.component';
import {FooterAlmentorComponent} from './footer-almentor/footer-almentor.component';
import {FooterExploreComponent} from './footer-explore/footer-explore.component';
import {FooterBusinessComponent} from './footer-business/footer-business.component';
import {FooterAppComponent} from './footer-app/footer-app.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxTranslateCutModule} from 'ngx-translate-cut';
import {RouterModule} from "@angular/router";
import { MainFooterV2Component } from './main-footer-v2/main-footer-v2.component';
import { FooterAlmentorV2Component } from './footer-almentor-v2/footer-almentor-v2.component';
import { FooterSocialLinksB2bComponent } from './footer-social-links-b2b/footer-social-links-b2b.component';

@NgModule({
    declarations: [FooterComponent, MainFooterComponent, SubFooterComponent, FooterSocialLinksComponent, FooterAlmentorComponent, FooterExploreComponent, FooterBusinessComponent, FooterAppComponent, MainFooterV2Component, FooterAlmentorV2Component, FooterSocialLinksB2bComponent],
    exports: [
        FooterComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxTranslateCutModule,
    RouterModule
  ]
})
export class FooterModule { }
