import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplifiedFooterComponent } from './simplified-footer.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxTranslateCutModule} from 'ngx-translate-cut';



@NgModule({
  declarations: [SimplifiedFooterComponent],
  exports: [
    SimplifiedFooterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxTranslateCutModule
  ]
})
export class SimplifiedFooterModule { }
