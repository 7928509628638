import {IPaymentMethod} from "@interfaces/common/payment.interface";
import {EPaymentStatusId, IPaymentStatus} from "@interfaces/payment-website/payment-status.interface";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";

export enum TrackingEventName {

    //SignUp
    SIGNUP_BUTTON_CLICKED = "Signup Button Clicked",
    SIGN_UP_WITH_SOCIAL_CLICKED = "SignUp With Social Selected",
    SIGNUP_WITH_EMAIL_SELECTED = "signup with email selected",
    SIGNUP_WITH_EMAIL_STARTED = "signup with email started",
    SIGNUP_ACTIVATION = "signup activation",
    SIGNUP_SUCCESSFULLY = "signup successfully",

    //LogIn
    LOGIN_BUTTON_CLICKED = "login Button clicked",
    LOGIN_WITH_EMAIL_CLICKED = "login with email clicked",
    LOGIN_WITH_SOCIAL_CLICKED = "Login with social clicked",
    LOGIN_SUCCESSFULLY = "login successfully",


    //Subscription
    SUBSCRIPTION_SELECT_PLAN = 'Subscription Select Plan',
    SUBSCRIPTION_SELECT_PAYMENT_METHOD = 'Subscription Select Payment Method',
    SUBSCRIPTION_PAYMENT_CONFIRMATION = 'Subscription Payment Confirmation',
    SUBSCRIPTION_PAYMENT_STATUS = 'Subscription Payment Status',
}

export type ITrackingEventsProperties = {
    id: number;
    email: string;
    facebook: string;
    plan_type: 'yearly' | 'monthly';
    payment_method: IPaymentMethod;
    payment_status: IPaymentStatus;
    amount: number;
    discount_percentage?: number;
    discount_coupon?: string;
    transaction_id: string;
    phone_number?: string;

    authentication_type: ELoginTypes;
    status?: boolean;
}

type TrackingEvents = [
    {
        name: TrackingEventName.SIGNUP_BUTTON_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.SIGN_UP_WITH_SOCIAL_CLICKED,
        properties: EventProperties<'authentication_type'>
    },
    {
        name: TrackingEventName.SIGNUP_WITH_EMAIL_SELECTED,
        properties?: undefined
    },
    {
        name: TrackingEventName.SIGNUP_WITH_EMAIL_STARTED,
        properties: EventProperties<'email' | 'status'>
    },
    {
        name: TrackingEventName.SIGNUP_ACTIVATION,
        properties: EventProperties<'authentication_type' | 'status'>
    },
    {
        name: TrackingEventName.SIGNUP_SUCCESSFULLY,
        properties: EventProperties<'authentication_type'>
    },

    {
        name: TrackingEventName.LOGIN_BUTTON_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.LOGIN_WITH_EMAIL_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.LOGIN_WITH_SOCIAL_CLICKED,
        properties: EventProperties<'authentication_type'>
    },
    {
        name: TrackingEventName.LOGIN_SUCCESSFULLY,
        properties: EventProperties<'authentication_type'>
    },

    {
        name: TrackingEventName.SUBSCRIPTION_SELECT_PLAN,
        properties: EventProperties<'plan_type'>,
    },
    {
        name: TrackingEventName.SUBSCRIPTION_SELECT_PAYMENT_METHOD,
        properties: EventProperties<'payment_method'>,
    },
    {
        name: TrackingEventName.SUBSCRIPTION_PAYMENT_CONFIRMATION,
        properties: EventProperties<
            'amount'
            | 'payment_method'
            | 'discount_coupon'
            | 'discount_percentage'
            | 'phone_number'
        >
    },
    {
        name: TrackingEventName.SUBSCRIPTION_PAYMENT_STATUS,
        properties: EventProperties<
            'payment_status'
            | 'amount'
            | 'payment_method'
            | 'discount_coupon'
            | 'discount_percentage'
            | 'phone_number'
            | 'transaction_id'
        >
    },

];

type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type TrackingEvent = ArrayElement<TrackingEvents>


type EventProperties<K extends keyof ITrackingEventsProperties> = Pick<ITrackingEventsProperties, K>

export interface ITrackingOptions {
  navigationEvent?: boolean
}
